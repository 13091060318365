import { axiosBase } from '@/shared/auth/axiosBase';
import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_ADMINISTRACAO_PORT}${process.env.VUE_APP_API_PATH}`;

export default {
    login(usuario) {
        return axiosBase.post(`${api}/account/login`, usuario);
    },

    atualizarSenha(usuario) {
        return axiosJwt.post(`${api}/account/atualizarsenha`, usuario);
    },

    recuperarSenha(usuario) {
        return axiosJwt.post(`${api}/account/recuperarsenha`, usuario);
    },
};
