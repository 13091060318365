<template>
    <div class="login-bg">
        <div class="p-d-flex p-jc-center">
            <Card style="width: 25em" class="p-mt-5">
                <template #header>
                    <div class="p-d-flex p-jc-center p-mt-4">
                        <img alt="user header" src="assets/layout/images/neofase_logo_login.jpg" style="width: 18em" />
                    </div>
                </template>
                <!-- <template #title>
                    <div class="p-text-center">
                        <h3>SGCE</h3>
                        Gestão para Casas Espíritas<br />
                        <span class="p-error p-mt-1">Atualize sua senha</span>
                    </div>
                </template> -->
                <template #content>
                    <erros-box :erros="erros"></erros-box>
                    <div class="p-formgrid p-grid p-fluid">
                        <!-- <div class="p-field p-col-12 p-mt-2">
                            <label><font-awesome-icon icon="landmark" /> Instituição</label><br />
                            <span
                                ><strong>{{ instituicao }}</strong></span
                            >
                        </div> -->
                        <div class="p-field p-col-12 p-mt-2">
                            <label><i class="pi pi-user"></i> Usuário</label><br />
                            <span
                                ><strong>{{ usuario }}</strong></span
                            >
                        </div>
                        <div class="p-field p-col-12 p-mt-2">
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">
                                    <i class="pi pi-key"></i>
                                </span>
                                <InputText type="password" v-model="senhaAtual" @input="v$.senhaAtual.$touch()" placeholder="Senha Atual" />
                            </div>
                            <small class="p-error" v-if="v$.senhaAtual.$error">Senha atual é obrigatória</small>
                        </div>
                        <div class="p-field p-col-12 p-mt-2">
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">
                                    <i class="pi pi-key"></i>
                                </span>
                                <InputText type="password" v-model="senhaNova" @input="v$.senhaNova.$touch()" placeholder="Nova Senha" />
                            </div>
                            <small class="p-error" v-if="v$.senhaNova.$error">Nova senha é obrigatória</small>
                        </div>
                        <div class="p-field p-col-12 p-mt-2">
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">
                                    <i class="pi pi-key"></i>
                                </span>
                                <InputText type="password" v-model="senhaNovaConfirmacao" @input="v$.senhaNovaConfirmacao.$touch()" placeholder="Nova Senha - Confirmação" @keypress.enter="recaptcha" />
                            </div>
                            <small class="p-error" v-if="v$.senhaNovaConfirmacao.$error">A confirmação da nova senha é obrigatória</small>
                        </div>
                    </div>
                    <div class="p-col-12 p-d-inline-flex p-flex-column p-jc-center">
                        <Button label="Atualizar Senha " icon="pi pi-check" @click="recaptcha" class="btnLogin" :disabled="v$.$invalid" />
                        <div class="p-mt-4 p-text-center">
                            <strong>v{{ versao }}</strong>
                        </div>
                    </div>
                </template>
            </Card>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import AuthServices from './AuthServices';

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            instituicao: this.$store.getters.instituicao.nome,
            usuario: this.$store.getters.usuario.nome,
            senhaAtual: '',
            senhaNova: '',
            senhaNovaConfirmacao: '',
            erros: [],
            versao: process.env.VUE_APP_VERSION,
        };
    },

    validations() {
        return {
            senhaAtual: { required },
            senhaNova: { required },
            senhaNovaConfirmacao: {
                required,
                senhasIguais: () => {
                    return this.senhaNova === this.senhaNovaConfirmacao;
                },
            },
        };
    },

    methods: {
        async recaptcha() {
            this.v$.$touch();
            if (this.v$.$invalid) return;
            this.$store.dispatch('addRequest');
            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('login');
            this.atualizarSenha(token);
        },

        atualizarSenha(token) {
            let atualizarSenhaDto = {
                senhaAtual: this.senhaAtual,
                senhaNova: this.senhaNova,
                token: token,
            };

            AuthServices.atualizarSenha(atualizarSenhaDto).then((response) => {
                if (response && response.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Usuário',
                        detail: 'Senha atualizada com sucesso',
                        life: 3000,
                    });
                    this.$store.dispatch('logout');
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
